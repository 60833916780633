body {
  margin: 0;
  background: black;
}

#root {
  height: 100%;
}

.container {
  display: flex;
  justify-content: flex-end;
}

.credits img {
  height: 15px;
  margin-right: 5px;
}

.animated-word-offset,
.animated-word {
  background-color: rgba(191, 212, 253, 0.5);
  bottom: 15px;
  font-family: Helvetica;
  letter-spacing: 0.1em;
  text-align: center;
  color: #2c2b10;
  cursor: pointer;
  max-width: 150px;
  width: 100%;
  outline: 2px solid;
  outline-color: rgba(191, 212, 253, 0.5);
  outline-offset: 5px;
  position: fixed;
  transition: all 600ms cubic-bezier(0.2, 0, 0, 0.8);
}

.animated-word-offset:hover {
  color: #fdfdff;
  outline-color: rgba(71, 126, 232, 0);
  outline-offset: 300px;
}

.animated-word:hover {
  color: #fdfdff;
  outline-color: rgba(71, 126, 232, 0);
}

.affirmation {
  bottom: 58px;
}

/* blinking cursor */
#cursor {
  animation: blink 0.8s infinite;
  background: white;
  line-height: 30px;
  margin-left: 3px;
  margin-top: 10px;
  padding-right: 10px;
  -webkit-animation: blink 1.2s infinite;
  width: 7px;
  height: 32px;
}

@keyframes blink {
  0% {
    background: white;
  }
  50% {
    background: transparent;
  }
  100% {
    background: white;
  }
}
